
.productManageTable {
  border-collapse: collapse;
  margin: 20px auto;
}
.productColumnWidth {
  width: 50%;
}
.dispOrderColumnWidth {
  width: 10%;
}
.updateColumnWidth {
  width: 15%;
}
th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

// th {
//     background-color: #f2f2f2;
// }

// tr:nth-child(even) {
//     background-color: #f2f2f2;
// }

.edit-button {
  padding: 5px 10px;
  cursor: pointer;
}
.confirm-button {
  position: absolute;
  right: 8px;
  top: 2px;
  font-size: 30px;
  cursor: pointer;
}
.edit-wrap {
  position: relative;
}
