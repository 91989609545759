
.site_title {
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 400;
  font-size: 22px;
  width: 100%;
  color: #717185 !important;
  margin-left: 0 !important;
  line-height: 59px;
  display: block;
  height: 50px;
  margin: 0;
  
  i {
    border: 1px solid #717185;
    padding: 5px 6px;
    border-radius: 50%;
  }
}
