
.picture-container {
  border: solid 1px #D9DEE2;
  height: 374px;
  flex-wrap: wrap;
  overflow: auto;
}
.square-container {
  aspect-ratio: 1 / 1;
  overflow: hidden;
  position: relative;
}
.square-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.search-icon {
  position:absolute;
  background-color: white;
  border-radius: 15px; 
  width: 30px;
  height: 30px;
  left: 3px;
  bottom: 3px;
  cursor: pointer;
}
