
.dp__button {
  display: none !important;
}
.checkRow {
  --easy-table-body-row-background-color: #f2f2f2;
}
.whiteRow {
  --easy-table-body-row-background-color: #fff;
}
.satColor {
  color: blue;
}
.sunColor {
  color: red;
}
