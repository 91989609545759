
.productManageTable {
  border-collapse: collapse;
  margin: 20px auto;
}
.productColumnWidth {
  width: 300px;
}
.productColumnWidthPer {
  width: 100px;
}
th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

// th {
//     background-color: #f2f2f2;
// }

// tr:nth-child(even) {
//     background-color: #f2f2f2;
// }

.edit-button {
  background-color: #4CAF50;
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}
.confirm-button {
  position: absolute;
  right: 8px;
  top: 4px;
  font-size: 30px;
  cursor: pointer;
}
.edit-wrap {
  position: relative;
}
